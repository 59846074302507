import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import "./Appointment.css";
import ActivityPage from "../../components/ActivityPage";
import { Grid } from "@mui/material";
import Chart from "../../components/patient/Chart";
import { toast, ToastContainer } from "react-toastify";

export default function Progress() {
  const [progressData, setProgressData] = useState([]);
  // const [initialData, setInitialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [actualGraph, setActualGraph] = useState([]); //for line graph - actual progress values array
  const [expectationGraph, setExpectationGraph] = useState([]); //for line graph - expectation values array

  // console.log(progressData);

  useEffect(() => {
    const createEmptyTableData = async () => {
      try {
        // const patientId = "64c108530d99356de2475a8d";
        const response = await axios.get(`${BASE_URL}/patient/details`, {
          withCredentials: true,
        });
        const subscriptions = response.data.subscriptionNum;

        console.log(subscriptions);
        // const subscriptions = 5;
        const numYears = Math.floor(subscriptions / 12) + 1;
        const numMonthsInLastYear = subscriptions % 12; //(1%12 = 1)
        console.log(subscriptions);

        const createEmptyWeekData = (weekNumber) => {
          return {
            weekNumber: weekNumber,
            therapist: null, // Set therapist to null as it's an ObjectID and should be initialized to null
            therapistName: "Therapist's name",
            meetLink: "google meet link",
            sessionRecording: "Session recording link",
            tips: {
              one: "Tip 1 here",
              two: "Tip 2 here",
              three: "Tip 3 here",
            },
            patientVideo: "Patient's video link",
            videoReview: "Therapist's review here",
            activities: Array.from({ length: 5 }, (_, activityIndex) => ({
              activityNumber: activityIndex + 1,
              instructions: "Instructions link",
              days: Array.from({ length: 7 }, (_, dayIndex) => ({
                day: dayIndex + 1,
                trials: Array(2).fill("none"),
              })),
            })),
          };
        };

        // Function to generate an empty month's data
        const createEmptyMonthData = (monthNumber) => {
          return {
            monthNumber: monthNumber,
            weeks: [
              createEmptyWeekData(1),
              createEmptyWeekData(2),
              createEmptyWeekData(3),
              createEmptyWeekData(4),
            ],
          };
        };

        const createEmptyYearData = (year, numMonths) => {
          return {
            year: year,
            months: Array.from({ length: numMonths }, (_, monthIndex) =>
              createEmptyMonthData(monthIndex + 1)
            ),
          };
        };

        const emptyProgressData = Array.from(
          { length: numYears },
          (_, yearIndex) => createEmptyYearData(yearIndex + 1, 12)
        );

        // Add the remaining months to the last year if needed
        if (numMonthsInLastYear > 0) {
          const lastYearIndex = emptyProgressData.length - 1;
          emptyProgressData[lastYearIndex].months.splice(numMonthsInLastYear); //removes extra months, say I have 5 subscriptions, 5%12=5, so spilce at 5, leaving array of 0 to 4 months

          // If the last year has no months, remove it from the progress data
          if (emptyProgressData[lastYearIndex].months.length === 0) {
            emptyProgressData.pop();
          }
        }

        // setInitialData(emptyProgressData);

        // useEffect(() => {
        const fetchProgressData = async () => {
          try {
            // const patientId = "64c108530d99356de2475a8d"; // Replace this with the actual patient ID
            const response = await axios.get(
              `${BASE_URL}/patient/get/progress`,
              {
                withCredentials: true,
              }
            );
            console.log("length-----", response.data.progressData.length);
            const dataFetched = response.data.progressData;

            // if (dataFetched.some((obj) => Object.keys(obj).length === 0)) {
            if (dataFetched.length === 0) {
              setProgressData(emptyProgressData);
              saveProgressDataToBackend("new", emptyProgressData);
            } else {
              setProgressData(response.data.progressData);
            }
            // if (response.data.progressData.length > 0) {
            //   console.log(response.data.progressData);
            //   setLoading(false);
            // }
            setLoading(false);
          } catch (error) {
            console.error("Error fetching progress data:", error);
            setLoading(false);
          }
        };

        // if (progressData.length === 0) {
        //   //so that it does not overwrite data fetched from backend with this empty progress data
        //   setProgressData(emptyProgressData);
        // }
        await fetchProgressData();

        // if (progressData.length === 0) {
        //   // if (progressData.some((obj) => Object.keys(obj).length === 0)) {
        //   console.log("Hellooo");
        //   // Only set the empty data if there is no existing data
        //   setInitialData(emptyProgressData);
        //   // saveProgressDataToBackend("new", emptyProgressData);
        // }

        // setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    createEmptyTableData();
  }, []);

  // // useEffect(() => {
  // const fetchProgressData = async () => {
  //   try {
  //     // const patientId = "64c108530d99356de2475a8d"; // Replace this with the actual patient ID
  //     const response = await axios.get(`${BASE_URL}/patient/get/progress`, {
  //       withCredentials: true,
  //     });
  //     console.log("length-----", response.data.progressData.length);
  //     const dataFetched = response.data.progressData;

  //     // if (dataFetched.some((obj) => Object.keys(obj).length === 0)) {
  //     if (dataFetched.length === 0) {
  //       setProgressData(initialData);
  //       saveProgressDataToBackend("new", initialData);
  //     } else {
  //       setProgressData(response.data.progressData);
  //     }
  //     // if (response.data.progressData.length > 0) {
  //     //   console.log(response.data.progressData);
  //     //   setLoading(false);
  //     // }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching progress data:", error);
  //     setLoading(false);
  //   }
  // };
  //   createEmptyTableData();
  //   fetchProgressData();
  // }, []);

  const handleCellChange = (
    yearIndex,
    monthIndex,
    weekIndex,
    dayIndex,
    activityIndex,
    trial,
    newValue
  ) => {
    setProgressData((prevProgressData) => {
      const updatedProgressData = [...prevProgressData];
      updatedProgressData[yearIndex].months[monthIndex].weeks[
        weekIndex
      ].activities[activityIndex].days[dayIndex].trials[trial] = newValue;

      saveProgressDataToBackend("update", updatedProgressData);

      return updatedProgressData;
    });
  };

  // const patientId = "64c108530d99356de2475a8d"; // Replace this with the actual patient ID

  const saveProgressDataToBackend = async (action, updatedProgressData) => {
    try {
      // Make the HTTP POST request to the backend API
      const response = await axios.post(
        `${BASE_URL}/patient/update/progress`,
        {
          progressData: updatedProgressData,
        },
        { withCredentials: true }
      );

      console.log(response.data);

      if (action === "update") {
        toast.success("Progress saved!");
        console.log("Progress data saved to backend!");
      }
    } catch (error) {
      console.error("Error saving progress data:", error);
    }
  };

  useEffect(() => {
    const trialsData = []; //array of objects with trial values and other info
    let trialsValueArray = []; //array of just trial values
    const progressGraphArray = []; //to plot line grapgh for patient progress
    const expectationGraphArray = [];

    progressData.forEach((yearData) => {
      const { year, months } = yearData;

      months.forEach((monthData) => {
        const { monthNumber, weeks } = monthData;

        weeks.forEach((weekData) => {
          const { weekNumber, activities } = weekData;

          //a nested loop for days
          for (let day = 1; day <= 7; day++) {
            // TO PUSH VALUES OF TRIAL 1 FOR EACH ACTIVITY EACH DAY
            activities.forEach((activityData) => {
              const { activityNumber, days } = activityData;

              // Find the specific dayData for the current day
              const dayData = days.find((dayData) => dayData.day === day);

              if (dayData) {
                const { trials } = dayData;

                const trialValue1 = trials[0];

                // Push the data to the lineChartData array
                trialsData.push({
                  // year: year,
                  // month: monthNumber,
                  // week: weekNumber,
                  activity: activityNumber,
                  day: day,
                  trial: "1",
                  trialValue: trialValue1,
                });
              }
            });

            // TO PUSH VALUES OF TRIAL 1 FOR EACH ACTIVITY EACH DAY
            activities.forEach((activityData) => {
              const { activityNumber, days } = activityData;

              // Find the specific dayData for the current day
              const dayData = days.find((dayData) => dayData.day === day);

              if (dayData) {
                const { trials } = dayData;

                const trialValue2 = trials[1];

                // Push the data to the lineChartData array
                trialsData.push({
                  // year: year,
                  // month: monthNumber,
                  // week: weekNumber,
                  activity: activityNumber,
                  day: day,
                  trial: "2",
                  trialValue: trialValue2,
                });
              }
            });
          }
        });
      });
    });
    trialsValueArray = trialsData?.map((data) => data.trialValue);
    // console.log(trialsData);
    // console.log(trialsValueArray);

    //ARRAYs FOR LINE GRAPH (expectation and progress)
    let progress = 0;
    let expectation = 0;
    let stopAddingValues = false;
    trialsValueArray.forEach((value) => {
      if (!stopAddingValues) {
        expectation++;
        expectationGraphArray.push(expectation);
        if (value === "success") {
          progress++;
          progressGraphArray.push(progress);
        } else {
          progressGraphArray.push(progress);
        }

        if (value === "none") {
          stopAddingValues = true;
        }
      }
    });
    setActualGraph(progressGraphArray);
    setExpectationGraph(expectationGraphArray);
    // console.log("PROGRESS---", progressGraphArray);
    // console.log("EXPECTATION---", expectationGraphArray);
  }, [progressData]);

  async function patientVideoUploadHandler(
    yearIndex,
    monthIndex,
    weekIndex,
    yearDataArray
  ) {
    console.log("Progress page---------------------------------");

    try {
      // Make the HTTP POST request to the backend API
      const response = await axios.post(
        `${BASE_URL}/patient/update/patientvideo`,
        {
          progressData: yearDataArray, //to save to db after therapist edited details
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success("Video saved to profile!");
      } else {
        toast.error("An error occurred!");
      }

      // console.log(response.data);

      console.log("Video saved successfully!");
    } catch (error) {
      toast.error("An error occurred!");
      console.error("Error saving video:", error);
    }
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  //----------------Chart data-------------
  // const data1 = [
  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  //   22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
  // ];
  // const data2 = [
  //   1, 1, 2, 2, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 17, 17,
  //   18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
  // ];

  const labels = expectationGraph;
  // const labels = [
  //   "1",
  //   "6",
  //   "11",
  //   "16",
  //   "21",
  //   "26",
  //   "31",
  //   "36",
  //   "41",
  //   "46",
  //   "51",
  //   "56",
  //   "61",
  //   "66",
  //   "71",
  //   "76",
  //   "81",
  //   "86",
  //   "91",
  //   "96",
  //   "101",
  //   "106",
  //   "111",
  //   "116",
  //   "121",
  //   "126",
  //   "131",
  //   "136",
  //   "141",

  // ];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Expected Progress",
        data: expectationGraph,
        borderColor: "#00bf63",
      },
      {
        label: "Actual Progress",
        data: actualGraph,
        fill: false,
        borderColor: "#2242a3",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category", // Use 'category' scale for X-axis when using labels
        title: {
          display: true,
          text: "Days",
        },
      },
      y: {
        title: {
          display: true,
          text: "Trials",
        },
      },
    },
  };

  // const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <ToastContainer />
      <Toolbar />

      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
        }}
      >
        <h4>My Progress</h4>
        <Grid
          container
          mt={4}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "5px",
            padding: "10px 20px 30px 20px",
          }}
        >
          <ActivityPage
            who="therapist"
            patientData={progressData}
            onCellChange={handleCellChange}
            onTableUpdate={patientVideoUploadHandler}
          />
        </Grid>

        <Grid item xs={12} lg="6">
          <Chart data={data} options={options} />
        </Grid>
      </Container>
    </Box>
  );
}

// if (progressData.some((obj) => Object.keys(obj).length === 0)) {
// This line of code checks if there is at least one object in the `progressData` array where the number of keys (properties) is equal to zero. In other words, it's checking if there is an empty object in `progressData`.

// Let's break it down step by step:
// 1. `progressData.some(...)`:
//    - `progressData` is an array.
//    - `.some()` is a JavaScript array method that checks if at least one element in the array satisfies a condition.

// 2. `(obj) => Object.keys(obj).length === 0`:
//    - This is an arrow function that serves as the condition for `.some()`.
//    - `obj` represents each element in `progressData`.
//    - `Object.keys(obj)` gets an array of keys from the object `obj`.
//    - `.length` gives the number of elements in that array of keys.
//    - `=== 0` checks if that number is exactly zero.

// 3. The condition:
//    - The condition `Object.keys(obj).length === 0` checks if the number of keys in the object `obj` is exactly zero.
//    - If this condition is `true`, it means that the object `obj` is empty (it has no properties).

// 4. How this works:
//    - `progressData.some(obj => Object.keys(obj).length === 0)` iterates over each object in `progressData`.
//    - For each object, it checks if the number of keys is exactly zero.
//    - If it finds at least one object where this condition is `true`, `progressData.some(...)` will return `true`.
//    - If none of the objects satisfy the condition (i.e., all objects have at least one property), `progressData.some(...)` will return `false`.

// 5. The `if` statement:
//    - `if (progressData.some(obj => Object.keys(obj).length === 0))` checks if `progressData.some(...)` returns `true`.
//    - This means that there is at least one empty object in `progressData`.
//    - If this condition is `true`, the code inside the `if` block will be executed.

// In summary, this code checks if there is at least one empty object in `progressData`. If so, the code inside the `if` block will be executed.
