import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import BasicNavbar from "../components/BasicNavbar";

function RefundCancellationPolicy() {
  return (
    <div className="App">
      <GoToTop />
      <div>
        <BasicNavbar />
      </div>
      <div className="content-div">
        <h1>Refund and Cancellation Policy</h1>
        <p>
          There is grace period of a month for the first time sign up. If you
          avail our services for a month (4 sessions) and if you decide to
          discontinue our services/platform, you will be refunded the entire
          amount that you had paid for the monthly subscription. In the
          subsequent month, if you decide to continue with our services, you
          need to make a payment of subscription fee for that month. Or you can
          choose to set up auto payment so that the subscription keeps renewing
          by itself every month.
        </p>
        <p>
          At any given week, if you wish to try a different therapist, you can
          do the same by booking the session with a different therapist based on
          the availability from a pool of therapists.
        </p>
        <p>
          The monthly subscription paid is non-refundable (except for the first
          month when you sign up) if you decide to discontinue our services in
          the middle of the month. For any month, if the subscription is not
          renewed until the 10th of the month, a gentle reminder shall be sent
          to the registered email every 24 hours until the 15th of the month. If
          no action is taken by the 15th of the month, the profile shall be
          deleted and you would no longer be able to login and access historical
          session recordings, activity tables, instructional videos etc.{" "}
        </p>
        <p>
          If you decide to cancel our services and don’t wish to receive
          reminders, you can send us an email at admin@xlspeech4all.com. In
          which case,we shall delete your profile from our portal within 24
          hours and you would no longer be able to login and access historical
          session recordings, activity tables, instructional videos etc.{" "}
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default RefundCancellationPolicy;
