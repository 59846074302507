import React, { useState } from "react";
import classes from "./Navbar.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
// import CloseIcon from '@mui/icons-material/Close';

function Navbar(props) {
  const [navbar, setNavbar] = useState(false);

  const navigate = useNavigate();

  const toggleNavbar = () => {
    // console.log("clicked")
    setNavbar(!navbar);
  };

  const sendToHome = () => {
    const element = document.getElementById("home");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (navbar) setNavbar(!navbar);
  };
  const sendToAbout = () => {
    const element = document.getElementById("about");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (navbar) setNavbar(!navbar);
  };
  const sendToProgram = () => {
    const element = document.getElementById("program");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (navbar) setNavbar(!navbar);
  };
  const sendToTherapist = () => {
    navigate("/therapist/auth");
    if (navbar) setNavbar(!navbar);
  };
  const sendToPatient = () => {
    navigate("/patient/auth");
    if (navbar) setNavbar(!navbar);
  };

  return (
    <nav className={classes.nav}>
      <img
        className={classes["logo-img"]}
        src={require("../../assets/logo.png")}
        alt="logo"
      />
      <div className={classes.hamburger}>
        {navbar ? (
          <p></p>
        ) : (
          <MenuIcon
            style={{ fill: "#fff", fontSize: "200%" }}
            onClick={toggleNavbar}
          />
        )}
      </div>
      <ul
        id={classes["nav-bar"]}
        className={navbar ? classes.active : classes.act}
      >
        {navbar && (
          <CloseIcon
            className={classes["close-icon"]}
            style={{ fill: "#fff", fontSize: "300%" }}
            onClick={toggleNavbar}
          />
        )}
        <li>
          <p onClick={sendToHome}>Home</p>
        </li>
        <li>
          <p onClick={sendToAbout}>About</p>
        </li>
        <li>
          <p onClick={sendToProgram}>Program Structure</p>
        </li>
        <li className={classes.dropdown}>
          <button className={`${classes["talk-btn"]} ${classes.btn}"`}>
            Register / Login
          </button>
          <div className={classes["dropdown-content"]}>
            <h5 onClick={sendToPatient}>Student</h5>
            <h5 onClick={sendToTherapist}>Therapist</h5>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
