import React from "react";
import "./Mission.css";

function Mission() {
  return (
    <div className="mission-section">
      <div className="mission container">
        <h2>VISION AND MISSION</h2>
        <p className="subheading">
          What we are doing, and why we are doing it.
        </p>
        <div className="row mt-4" style={{ justifyContent: "space-evenly" }}>
          <div className="col-lg-4">
            <div
              className="card vision-card"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <h3>Our Vision</h3>
              <div className="vision-content">
                <p>
                  Our vision is to empower individuals to express themselves and
                  thereby improve the quality of their lives and their families
                  by ensuring access to quality speech therapy and parental
                  training to the needy anywhere in the world.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-6">
            <div
              className="card vision-card"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <h3>Our Mission</h3>
              <div className="vision-content">
                <p>
                  Our mission is to offer not only a high-quality speech therapy
                  sessions involving advanced techniques at a most affordable
                  price but also to progressively empower the parents/caretakers
                  to become therapists for their kids to ensure right stimulus
                  at the right time in order to bring out one’s innate ability
                  to communicate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
