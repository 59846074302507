import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import DateCalendarServerRequest from "../../components/Calendar";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import "./Appointment.css";
import "./Therapists.css";
import "./PatientDetail.css";
import { BASE_URL } from "../../apiConfig";

const Button = ({ handleClick, id }) => {
  // const [activeId, setActiveId] = useState(null);
  const btnRef = useRef(null);
  // console.log("btn Ref" + btnRef);
  // console.log("button" + id);
  return (
    <button
      ref={btnRef}
      onClick={() => handleClick(id, btnRef)}
      className="bookAppointmentBtn"
    >
      Book Appointment
    </button>
  );
};

export default function Therapists(props) {
  const { modalProps, open } = useModal();

  const navigate = useNavigate();
  const [therapistData, setTherapistData] = useState([]);

  const [slotData, setSlotData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mainSlotData, setMainSlotData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [therapistId, setTherapistId] = useState(""); //to pass the id of currently opened modal therapist to DateCalendarServerRequest to get thirty minute slots based on dates

  // console.log("Iddddddd" + therapistId);

  function gotodoctorprofilepage(id) {
    if (props.admin) {
      navigate(`/admin/therapist/therapist-detail/${id}`);
      // window.location.href = "/admin/therapist/therapist-detail"
    } else {
      navigate(`/patient/therapist/therapist-detail/${id}`);
      // window.location.href = "/patient/therapist/therapist-detail"
    }
  }

  // const { modalProps, getTriggerProps, activeModal } = useModal({
  //   background: "#21252994;",
  // });

  //fetch all therapists
  useEffect(() => {
    fetch(`${BASE_URL}/therapist/getAllTherapist`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setTherapistData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, []);

  function handleClick(id, btnRef) {
    // console.log(id);
    // console.log(btnRef);
    open(btnRef);
    setTherapistId(id);
    setSlotData([]);

    fetch(`${BASE_URL}/therapist/getprofile/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setMainSlotData(data.mainSlot);
        //  console.log(data.mainSlot)
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }

  const [slotId, setSlotId] = useState("");

  const handleSlotDataReceived = (data) => {
    console.log("settingggg");
    setSlotData(data);
    setIsLoading(false);
  };

  const handleSlotId = (id) => {
    setSlotId(id);
    console.log(id);
  };

  const handleBookSlot = () => {
    const bookSlot = () => {
      fetch(`${BASE_URL}/slot/book/${slotId}`, {
        method: "POST",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Slot has been booked!");
          console.log(data.thirtyMinSlot);
          if (data.success) {
            toast.success(data.message || "Slot booked successfully!");
          } else {
            toast.error(data.message || "Error booking slot!");
          }
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
          toast.error("Error booking slot! Please try again.");
        });
    };

    bookSlot();
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#f5f5f7",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <Grid container mb={2}>
          <Grid item xs={12}>
            {props.admin ? (
              <h5 style={{ fontWeight: "600" }}>All Therapists</h5>
            ) : (
              <>
                <h5 style={{ fontWeight: "600" }}>Book Appointment</h5>
                <p>
                  Book appointments with minimum wait-time & verified therapist
                </p>
              </>
            )}
          </Grid>
        </Grid>

        <Paper
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          {therapistData?.map((therapist, index) => (
            <div key={therapist._id}>
              <Grid
                // key={therapist._id}
                container
                spacing={1}
                sx={{
                  padding: "10px",
                }}
              >
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={3}
                  lg={3}
                  xl={2}
                  onClick={() => gotodoctorprofilepage(therapist._id)}
                >
                  {!therapist.profilePic ? (
                    <img
                      className="patient-detail-img"
                      src={require("../../images/person.jpg")}
                      alt="appimg"
                    />
                  ) : (
                    <img
                      className="patient-detail-img"
                      src={`${BASE_URL}/upload/${therapist.profilePic}`}
                      alt="appimg"
                    />
                  )}
                  {/* <img
                    className="patient-detail-img"
                    src={require("../../images/person.jpg")}
                    alt="personImg"
                  /> */}
                </Grid>

                <Grid
                  item
                  xs={7}
                  sm={6}
                  md={5}
                  lg={3}
                  xl={3}
                  sx={{
                    "margin-top": "20px",
                  }}
                >
                  <h6
                    onClick={() => gotodoctorprofilepage(therapist._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <strong>{therapist.fullName}</strong>
                  </h6>
                  <p>{`${therapist.country} ${therapist.timezone}`}</p>
                  <p
                    style={{ margin: "0" }}
                  >{`${therapist.yearsOfExp}+ years of experience`}</p>
                </Grid>
                {!props.admin && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={6}
                    xl={6}
                    className="bookAppointmentBtnSection"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: "5px" }}>Check Availability</p>

                    <Button id={therapist._id} handleClick={handleClick} />
                    {/* <span>{activeModal}</span> */}
                    <Modal {...modalProps}>
                      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                        <ToastContainer zIndex={1000000} />

                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            className="appointmentContainer"
                            item
                            xs={12}
                            md={12}
                            lg={8}
                          >
                            <Paper
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                height: "auto",
                                overflowX: "scroll",
                              }}
                            >
                              <h5>Select Slot</h5>
                              {/* <h6>
                                Click on a highlighted date to view available
                                slots.
                              </h6> */}
                              <DateCalendarServerRequest
                                therapistId={therapistId}
                                data={mainSlotData}
                                onSlotDataReceived={handleSlotDataReceived}
                              />
                              <form>
                                <Grid
                                  container
                                  maxWidth="md"
                                  spacing={2}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    textAlign: "center",
                                    gap: "20px 15px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {slotData?.map((data, index) => (
                                    <>
                                      {/* {isLoading && <p>Loading slots...</p>} */}
                                      {data.status === false ? (
                                        <>
                                          <Grid
                                            item
                                            xs={9}
                                            sm={5}
                                            md={4}
                                            lg={5}
                                            className="time-button"
                                          >
                                            <input
                                              type="radio"
                                              id="a25"
                                              name="check-substitution-2"
                                              onChange={() =>
                                                handleSlotId(data._id)
                                              }
                                            />
                                            <label
                                              class="btn btn-default"
                                              for="a25"
                                            >
                                              {`${data.startTime}-${data.endTime}`}
                                            </label>
                                          </Grid>
                                        </>
                                      ) : (
                                        <>
                                          <Grid
                                            item
                                            xs={9}
                                            sm={5}
                                            md={4}
                                            lg={5}
                                            className="time-button"
                                          >
                                            <input
                                              type="radio"
                                              id="a25"
                                              name="check-substitution-2"
                                              onChange={() =>
                                                handleSlotId(data._id)
                                              }
                                            />
                                            <label
                                              style={{
                                                backgroundColor: "#E48586",
                                                cursor: "auto",
                                              }}
                                              class="btn btn-default"
                                              for="a25"
                                            >
                                              {`${data.startTime}-${data.endTime}`}
                                            </label>
                                          </Grid>
                                        </>
                                      )}
                                      {/* </Grid> */}
                                    </>
                                  ))}
                                </Grid>
                              </form>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <Grid item>
                                  <button
                                    style={{
                                      padding: "8px 30px",
                                      border: "none",
                                      borderRadius: "5px",
                                      backgroundColor: "#00bf63",
                                      color: "#fff",
                                    }}
                                    onClick={() =>
                                      handleBookSlot(therapist._id)
                                    }
                                  >
                                    Confirm
                                  </button>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Container>
                    </Modal>
                  </Grid>
                )}
              </Grid>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <hr style={{ width: "90%" }} />
              </div>
            </div>
          ))}
        </Paper>
      </Container>
    </Box>
  );
}
