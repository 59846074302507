import React from "react";
import Footer from "../components/Footer";
import Signin from "../components/Signin";
import Navbar from "../components/Navbar";
import AnimatedCursor from "react-animated-cursor";
import BasicNavbar from "../components/BasicNavbar";

function TherapistSignin(props) {
  // console.log(window.location.pathname);

  return (
    <div>
      {/* <Navbar /> */}
      <BasicNavbar />

      <Signin page={props.page} />

      <Footer />
    </div>
  );
}

export default TherapistSignin;
