import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { BASE_URL } from "../apiConfig";

const currentDate = new Date();

// Extract year, month, and day components
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
const day = String(currentDate.getDate()).padStart(2, "0");

// Format the date as "YYYY-MM-DD"
const formattedDate = `${year}-${month}-${day}`;

// console.log(formattedDate)

const initialValue = dayjs(formattedDate);
// console.log(initialValue);

function ServerDay(props) {
  const {
    day,
    outsideCurrentMonth,
    highlightedDates,
    handleDateClick,
    ...other
  } = props;
  const dayNumber = day.date();

  const isSelected =
    !outsideCurrentMonth && highlightedDates.includes(dayNumber);

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "🟢" : undefined}
      onClick={() => handleDateClick(dayNumber)}
      style={{ cursor: "pointer" }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest({
  data,
  therapistId,
  onSlotDataReceived,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingDates, setLoadingDates] = useState(true);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    const currentMonth = dayjs().format("MMM");
    setSelectedMonth(currentMonth);
    // console.log("data", data);

    // GET AVAILABLE DATES TO HIGHLIGHT BASED ON LOGGED IN PATIENT'S TIMEZONE AS CALENDAR OPENS
    const fetchAvailableDates = () => {
      fetch(`${BASE_URL}/slot/filterbymonth/${therapistId}/${currentMonth}`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setHighlightedDates(data);
          setLoadingDates(false);
          // onSlotDataReceived(data);
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
        });
    };

    // // TO FETCH SLOTS FOR CURRENT DATE AS CALENDAR OPENS
    // const currentDate = dayjs().format("MMM DD");
    // console.log(currentDate);
    // console.log(`${BASE_URL}/slot/filter/${therapistId}/${currentDate}`);

    // const fetchSlotData = () => {
    //   fetch(`${BASE_URL}/slot/filter/${therapistId}/${currentDate}`, {
    //     credentials: "include",
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log(data);
    //       onSlotDataReceived(data);
    //       setIsLoading(false);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching slot data:", error);
    //       setIsLoading(false);
    //     });
    // };

    fetchAvailableDates();

    // fetchSlotData();

    setIsLoading(false);
  }, [data, therapistId]);

  const handleMonthChange = (date) => {
    setIsLoading(true);
    console.log(date);
    onSlotDataReceived([]);
    setHighlightedDates([]);
    const month = dayjs(date).format("MMM");
    console.log("selected month", month);
    setSelectedMonth(month);

    // GET AVAILABLE DATES TO HIGHLIGHT BASED ON LOGGED IN PATIENT'S TIMEZONE
    // const fetchAvailableDates = () => {
    fetch(`${BASE_URL}/slot/filterbymonth/${therapistId}/${month}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setHighlightedDates(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching slot data:", error);
        setIsLoading(false);
      });
    // };

    // fetchAvailableDates();

    // // Fetch highlighted dates for the new month if needed
    // const newMonthDates = data
    //   .filter((item) => {
    //     const itemMonth = dayjs(item.date, "MMM DD").month();
    //     return itemMonth === date.month();
    //   })
    //   .map((item) => parseInt(item.date.split(" ")[1], 10));

    //   console.log(newMonthDates)

    // setHighlightedDates(newMonthDates);
  };

  const handleDateClick = (selectedDay) => {
    // Handle date selection if needed
    console.log("Selected day:", selectedDay);
    setSelectedDate(selectedDay);

    console.log(selectedMonth);

    const combinedDate = `${selectedMonth} ${selectedDay}`;
    console.log("Combined date:", combinedDate);

    const fetchSlotData = () => {
      fetch(`${BASE_URL}/slot/filter/${therapistId}/${combinedDate}`, {
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          onSlotDataReceived(data);
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
        });
    };

    fetchSlotData();
  };

  return (
    <>
      {loadingDates ? (
        <h6>Loading available slots...</h6>
      ) : (
        <h6>Click on a highlighted ("🟢") date to view available slots.</h6>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          // defaultCalendarMonth={dayjs().startOf("month")}
          defaultValue={dayjs()}
          // defaultValue={initialValue}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          onDateChange={handleDateClick}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDates,
              handleDateClick,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}
