import React from "react";
import { Link, useParams } from "react-router-dom";
import "./ActivityPage.css";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";

const ActivityPage = (props) => {
  const [yearDataArray, setYearDataArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [therapistId, setTherapistId] = useState(null); //id of currently signed in therapist

  //to set initial value of input fields-------------------------------------------------
  useEffect(() => {
    const fetchTherapistId = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/therapist/id`, {
          withCredentials: true,
        });
        const id = response.data.therapistId;
        setTherapistId(id);
        console.log(id);
      } catch (error) {
        console.error("Error fetching id:", error);
      }
    };

    fetchTherapistId();

    // Initialize yearDataArray based on fetchedYearlyData that is props.patientData
    // to set initial values of input fields based on default values of data fetched from backend
    // const initialYearData = [...props.patientData]; //THIS CREATES A SHALLOW COPY OF THE NESTED DATA

    const initialYearData = JSON.parse(JSON.stringify(props.patientData)); //THIS CREATES A DEEP COPY WHICH IS USEFUL HERE COZ THE DATA IS NESTED AT MULTIPLE LEVELS

    setYearDataArray(initialYearData);
    setIsLoading(false);
  }, [props.patientData]);

  //FOR PATIENT PANEL = onChange handler function for patient video upload------------------------------------------------
  const handlePatientVideoUpload = async (
    yearIndex,
    monthIndex,
    weekIndex,
    value
  ) => {
    const updatedYearDataArray = JSON.parse(JSON.stringify(yearDataArray)); //THIS CREATES A DEEP COPY WHICH IS USEFUL HERE COZ THE DATA IS NESTED AT MULTIPLE LEVELS
    const weekToUpdate =
      updatedYearDataArray[yearIndex].months[monthIndex].weeks[weekIndex];

    const patientVideoFilename = await toast.promise(
      uploadFileToBackend(value, "patient"),
      {
        pending: "Uploading video...",
        success: "Video uploaded. Click submit to save.",
        error: "Error uploading video!",
      }
    ); //value = e.target.files[0]
    weekToUpdate.patientVideo = patientVideoFilename;
    console.log(patientVideoFilename);
    setYearDataArray(updatedYearDataArray);
    // await props.onTableUpdate(yearIndex, monthIndex, weekIndex, yearDataArray);
  };

  //FOR THERAPIST PANEL = onChange handler function for input fields------------------------------------------------
  const setWeekDataValue = async (
    yearIndex,
    monthIndex,
    weekIndex,
    field,
    value,
    activityIndex
  ) => {
    // const updatedYearDataArray = [...yearDataArray];
    const updatedYearDataArray = JSON.parse(JSON.stringify(yearDataArray)); //THIS CREATES A DEEP COPY WHICH IS USEFUL HERE COZ THE DATA IS NESTED AT MULTIPLE LEVELS

    const weekToUpdate =
      updatedYearDataArray[yearIndex].months[monthIndex].weeks[weekIndex];

    // CHECK IF THE WEEK BEING EDITED BELONGS TO THE LOGGED IN THERAPIST OR HAS NULL VALUE FOR THERAPIST (HASN'T BEEN EDITED BY ANY THERAPIST)
    if (
      weekToUpdate.therapist === null ||
      weekToUpdate.therapist === therapistId
    ) {
      weekToUpdate.therapist = therapistId; //set therapist id for therapist field for the week being edited to currently logged in therapist's id in case the value was null

      if (field.startsWith("therapistName")) {
        weekToUpdate.therapistName = value;
      } else if (field.startsWith("sessionRecording")) {
        const recordingFilename = await toast.promise(
          uploadFileToBackend(value, "therapist"),
          {
            pending: "Uploading File...",
            success: "File uploaded successfully!",
            error: "Error uploading file!",
          }
        ); //value = e.target.files[0]
        weekToUpdate.sessionRecording = recordingFilename;
        // weekToUpdate.sessionRecording = value; //value = e.target.files[0]
        console.log(value);
      } else if (field.startsWith("one")) {
        weekToUpdate.tips.one = value;
      } else if (field.startsWith("two")) {
        weekToUpdate.tips.two = value;
      } else if (field.startsWith("three")) {
        weekToUpdate.tips.three = value;
      } else if (field.startsWith("activityNumber")) {
        weekToUpdate.activities[activityIndex].activityNumber = value;
      } else if (field.startsWith("videoReview")) {
        weekToUpdate.videoReview = value;
      } else if (field.startsWith("instructions")) {
        const instructionFilename = await toast.promise(
          uploadFileToBackend(value, "therapist"),
          {
            pending: "Uploading File...",
            success: "File uploaded successfully!",
            error: "Error uploading file!",
          }
        ); //value = e.target.files[0]
        weekToUpdate.activities[activityIndex].instructions =
          instructionFilename;
        console.log(instructionFilename);
        // weekToUpdate.activities[activityIndex].instructions = value; //value = e.target.files[0]
        // console.log(value, activityIndex);
      }
    } else {
      toast.error("You don't have permission to edit this week!");
    }

    setYearDataArray(updatedYearDataArray);
    // console.log(updatedYearDataArray);
  };

  // TO UPLOAD FILES TO SERVER, SUCH AS SESSION RECORDING, ACTIVITY INSTRUCTIONS ETC
  // this function returns file name of uploaded file which is then saved in progressData of the respective patient
  function uploadFileToBackend(file, byWho) {
    return new Promise(async (resolve, reject) => {
      if (!(file instanceof File)) {
        resolve(file);
        return;
      }

      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await axios.post(
          `${BASE_URL}/${byWho}/upload`,
          formData
        );

        if (response.status === 200) {
          // toast.success("File uploaded successfully!");
          console.log("File uploaded successfully!");
          resolve(response.data.fileName); //backend returns the uploaded file name
        } else {
          // toast.error("Error uploading file!");
          console.error("File upload failed:", response.data.error);
          reject(response.data.error);
        }
      } catch (error) {
        // toast.error("Error uploading file!");
        console.error("File upload failed:", error);
        reject(error);
      }
    });
  }

  //to submit form data and save to backend---------------------------------------------------------------------------------
  function tableUpdateHandler(e, yearIndex, monthIndex, weekIndex) {
    e.preventDefault();

    props.onTableUpdate(yearIndex, monthIndex, weekIndex, yearDataArray);

    // let weekToUpdate =
    //   yearDataArray[yearIndex].months[monthIndex].weeks[weekIndex];

    // if (
    //   weekToUpdate.therapist === null ||
    //   weekToUpdate.therapist === therapistId
    // ) {
    //   yearDataArray[yearIndex].months[monthIndex].weeks[weekIndex].therapist =
    //     therapistId; //in case of null value, sets therapist field for updated week to currently logged in therapist's id who will be updating the table
    //   props.onTableUpdate(yearIndex, monthIndex, weekIndex, yearDataArray);
    // } else {
    //   toast.error("You don't have permission to update this week!");
    // }
  }

  // FUNCTION TO HANDLE FILE DOWNLOAD ON CLICK OF LINKS OR BUTTONS
  const handleDownload = async (file) => {
    try {
      const response = await axios.get(`${BASE_URL}/download/${file}`, {
        responseType: "blob",
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");

        //set link attributes
        a.href = url;
        a.download = file;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("File not found");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("No file exists!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  if (isLoading) {
    return <p>Loading progress data...</p>;
  }

  return (
    <Accordion
      transition={{
        duration: "100ms",
        timingFunction: "cubic-bezier(0, 0, 0.2, 1)",
      }}
      className="accordian col-xs-12"
    >
      <ToastContainer />
      {props.patientData?.map((yearData, yearIndex) => (
        <AccordionItem
          className="w-full year col-xs-12"
          key={`year_${yearData.year}`}
        >
          {({ open }) => (
            <>
              <AccordionHeader className="button col-xs-12">
                <h4
                  className={`accordion-title ${
                    open ? "accordion-active" : ""
                  }`}
                >
                  Year {yearData.year}{" "}
                </h4>
                <KeyboardArrowDownIcon />
              </AccordionHeader>
              <AccordionBody>
                {yearData?.months?.map((monthData, monthIndex) => (
                  <AccordionItem
                    className="month accordion-body col-xs-12"
                    key={`month_${yearData.year}_${monthIndex}`}
                  >
                    {({ open2 }) => (
                      <>
                        <AccordionHeader className="button button-level2 col-xs-12">
                          <h5
                            className={`accordion-title ${
                              open2 ? "accordion-active" : ""
                            }`}
                          >
                            Month {monthData.monthNumber}{" "}
                          </h5>
                          <KeyboardArrowDownIcon />
                        </AccordionHeader>
                        <AccordionBody>
                          {monthData?.weeks?.map((weekData, weekIndex) => (
                            <AccordionItem
                              className="week accordion-body"
                              key={`week_${monthIndex}_${weekIndex}`}
                            >
                              {({ open3 }) => (
                                <>
                                  <AccordionHeader className="button button-level3">
                                    <h6
                                      className={`accordion-title ${
                                        open3 ? "accordion-active" : ""
                                      }`}
                                    >
                                      Week {weekData.weekNumber}{" "}
                                    </h6>
                                    <KeyboardArrowDownIcon />
                                  </AccordionHeader>
                                  <AccordionBody>
                                    <form
                                      className={`week_${monthIndex}_${weekIndex}`}
                                      key={`week_${monthIndex}_${weekIndex}`}
                                      onSubmit={(e) =>
                                        tableUpdateHandler(
                                          e,
                                          yearIndex,
                                          monthIndex,
                                          weekIndex
                                        )
                                      }
                                    >
                                      <div className="activity-content">
                                        <h5>Session By :</h5>

                                        {props.edit && props.therapistView ? (
                                          <input
                                            className={`week_${monthIndex}_${weekIndex}`}
                                            value={
                                              yearDataArray[yearIndex].months[
                                                monthIndex
                                              ].weeks[weekIndex].therapistName
                                            }
                                            type="text"
                                            //name="therapistName"
                                            onChange={(e) =>
                                              setWeekDataValue(
                                                yearIndex,
                                                monthIndex,
                                                weekIndex,
                                                "therapistName",
                                                e.target.value
                                              )
                                            }
                                          />
                                        ) : (
                                          <p>{weekData.therapistName}</p>
                                        )}

                                        {/* {weekData.meetLink} */}
                                        {/* <br /> */}
                                        <h5>Session Recording</h5>
                                        {props.edit && props.therapistView ? (
                                          <input
                                            type="file"
                                            name={`sessionRecording_year${
                                              yearIndex + 1
                                            }_month${monthIndex + 1}_week${
                                              weekIndex + 1
                                            }`}
                                            onChange={(e) =>
                                              setWeekDataValue(
                                                yearIndex,
                                                monthIndex,
                                                weekIndex,
                                                "sessionRecording",
                                                e.target.files[0]
                                              )
                                            }
                                          />
                                        ) : (
                                          <button
                                            type="button"
                                            className="wrapperButton"
                                            // to={`${BASE_URL}/download/${weekData.sessionRecording}`}
                                            // download
                                            onClick={() =>
                                              handleDownload(
                                                weekData.sessionRecording
                                              )
                                            }
                                          >
                                            <button
                                              type="button"
                                              className="sessionRecordingBtn"
                                            >
                                              Session Recording{" "}
                                              <DownloadIcon fontSize="small" />
                                            </button>
                                          </button>
                                        )}

                                        <div className="tipsDiv">
                                          <h5>Three tips for daily routine</h5>
                                          {props.therapistView && props.edit ? (
                                            <>
                                              {["one", "two", "three"].map(
                                                (tipNumber) => (
                                                  <div
                                                    className="tip"
                                                    key={tipNumber}
                                                  >
                                                    <label>
                                                      Tip{" "}
                                                      {tipNumber === "one" &&
                                                        "1"}
                                                      {tipNumber === "two" &&
                                                        "2"}
                                                      {tipNumber === "three" &&
                                                        "3"}
                                                    </label>
                                                    <input
                                                      value={
                                                        yearDataArray[yearIndex]
                                                          .months[monthIndex]
                                                          .weeks[weekIndex]
                                                          .tips[tipNumber] //write tipNumber in [] coz these are string values "one", "two", "three", so instead to using dot notation as tips.one we'll use [] coz "one" is in string form
                                                      }
                                                      type="text"
                                                      // name={tipNumber}
                                                      onChange={(e) =>
                                                        setWeekDataValue(
                                                          yearIndex,
                                                          monthIndex,
                                                          weekIndex,
                                                          tipNumber,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <ul>
                                              <li>{weekData.tips.one}</li>
                                              <li>{weekData.tips.two}</li>
                                              <li>{weekData.tips.three}</li>
                                            </ul>
                                          )}
                                        </div>

                                        {props.therapistView && props.edit && (
                                          <>
                                            <h5>Assign Activities</h5>
                                            <div className="row mt-3 mb-3">
                                              {[1, 2, 3, 4, 5].map(
                                                (activityIndex) => (
                                                  <div
                                                    className="col-md-12"
                                                    key={activityIndex}
                                                  >
                                                    <label
                                                      className="labels"
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      Activity {activityIndex}
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="activity"
                                                      value={
                                                        yearDataArray[yearIndex]
                                                          .months[monthIndex]
                                                          .weeks[weekIndex]
                                                          .activities[
                                                          activityIndex - 1
                                                        ].activityNumber
                                                      }
                                                      onChange={(e) =>
                                                        setWeekDataValue(
                                                          yearIndex,
                                                          monthIndex,
                                                          weekIndex,
                                                          "activityNumber",
                                                          e.target.value,
                                                          activityIndex - 1
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </>
                                        )}

                                        <table>
                                          <thead>
                                            <tr>
                                              <th rowSpan="2">Activity</th>
                                              <th colSpan="2" className="day">
                                                Day 1
                                              </th>
                                              <th colSpan="2" className="day">
                                                Day 2
                                              </th>
                                              <th colSpan="2" className="day">
                                                Day 3
                                              </th>
                                              <th colSpan="2" className="day">
                                                Day 4
                                              </th>
                                              <th colSpan="2" className="day">
                                                Day 5
                                              </th>
                                              <th colSpan="2" className="day">
                                                Day 6
                                              </th>
                                              <th colSpan="2" className="day">
                                                Day 7
                                              </th>
                                            </tr>
                                            <tr>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                              <th className="trial1">T 1</th>
                                              <th className="trial2">T 2</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {weekData?.activities?.map(
                                              (activityData, activityIndex) => (
                                                <tr
                                                  key={`activity_${monthIndex}_${weekIndex}_${activityIndex}`}
                                                >
                                                  <td className="activity">{`${activityData.activityNumber}`}</td>
                                                  {activityData?.days?.map(
                                                    (dayData, dayIndex) => (
                                                      <React.Fragment
                                                        key={`activity_${monthIndex}_${weekIndex}_${activityIndex}_day_${dayIndex}`}
                                                      >
                                                        {/* FOR TRIAL ONE */}
                                                        <td
                                                          key={`activity_${monthIndex}_${weekIndex}_${activityIndex}_day_${dayIndex}_trial_0`}
                                                        >
                                                          <select
                                                            disabled={
                                                              props.therapistView ||
                                                              props.adminView
                                                            } //disable the cell if therapist or admin is viewing it
                                                            style={{
                                                              outline: "none",
                                                            }}
                                                            value={
                                                              dayData.trials[0]
                                                            }
                                                            onChange={(e) =>
                                                              props.onCellChange(
                                                                yearIndex,
                                                                monthIndex,
                                                                weekIndex,

                                                                dayIndex,
                                                                activityIndex,
                                                                0, // Use 0 for the first trial
                                                                e.target.value
                                                              )
                                                            }
                                                          >
                                                            <option
                                                              value="none"
                                                              disabled
                                                              hidden
                                                            >
                                                              -
                                                            </option>
                                                            <option value="not attempted">
                                                              NA
                                                            </option>
                                                            <option
                                                              style={{
                                                                color: "red",
                                                              }}
                                                              value="attempted"
                                                            >
                                                              A
                                                            </option>
                                                            <option
                                                              style={{
                                                                color: "green",
                                                              }}
                                                              value="success"
                                                            >
                                                              S
                                                            </option>
                                                          </select>
                                                        </td>
                                                        {/* FOR TRIAL TWO */}
                                                        <td
                                                          key={`activity_${monthIndex}_${weekIndex}_${activityIndex}_day_${dayIndex}_trial_1`}
                                                        >
                                                          <select
                                                            disabled={
                                                              props.therapistView ||
                                                              props.adminView
                                                            } //disable the cell if therapist is viewing it
                                                            style={{
                                                              outline: "none",
                                                            }}
                                                            value={
                                                              dayData.trials[1]
                                                            }
                                                            onChange={(e) =>
                                                              props.onCellChange(
                                                                yearIndex,
                                                                monthIndex,
                                                                weekIndex,

                                                                dayIndex,
                                                                activityIndex,
                                                                1, // Use 1 for the second trial
                                                                e.target.value
                                                              )
                                                            }
                                                          >
                                                            <option
                                                              value="none"
                                                              disabled
                                                              hidden
                                                            >
                                                              -
                                                            </option>
                                                            <option value="not attempted">
                                                              NA
                                                            </option>
                                                            <option
                                                              style={{
                                                                color: "red",
                                                              }}
                                                              value="attempted"
                                                            >
                                                              A
                                                            </option>
                                                            <option
                                                              style={{
                                                                color: "green",
                                                              }}
                                                              value="success"
                                                            >
                                                              S
                                                            </option>
                                                          </select>
                                                        </td>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                                  {props.edit &&
                                                  props.therapistView ? (
                                                    <input
                                                      type="file"
                                                      name={`instruction${
                                                        activityIndex + 1
                                                      }`}
                                                      onChange={(e) =>
                                                        setWeekDataValue(
                                                          yearIndex,
                                                          monthIndex,
                                                          weekIndex,
                                                          "instructions",
                                                          e.target.files[0],
                                                          activityIndex
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    <button
                                                      type="button"
                                                      className="wrapperButton"
                                                      // to={`${BASE_URL}/download/${activityData.instructions}`}
                                                      // download
                                                      style={{
                                                        textDecoration: "none",
                                                        color: "darkBlue",
                                                        marginLeft: "5px",
                                                      }}
                                                      onClick={() =>
                                                        handleDownload(
                                                          activityData.instructions
                                                        )
                                                      }
                                                    >
                                                      Instructions{" "}
                                                      <DownloadIcon fontSize="small" />
                                                    </button>
                                                  )}
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                        <div className="uploadVideoDiv">
                                          {props.therapistView ||
                                          props.adminView ? (
                                            <div>
                                              <h5>
                                                Uploaded video by patient{" "}
                                                <span> *(On Day 3) </span>
                                              </h5>
                                              <button
                                                type="button"
                                                className="wrapperButton"
                                                // to={`${BASE_URL}/download/${weekData.patientVideo}`}
                                                // download
                                                onClick={() =>
                                                  handleDownload(
                                                    weekData.patientVideo
                                                  )
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-success"
                                                >
                                                  Video{" "}
                                                  <DownloadIcon fontSize="small" />
                                                </button>
                                              </button>
                                              <div
                                                style={{ marginTop: "15px" }}
                                              >
                                                <h6
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Therapist Review
                                                </h6>
                                                {props.edit ? (
                                                  <textarea
                                                    value={
                                                      yearDataArray[yearIndex]
                                                        .months[monthIndex]
                                                        .weeks[weekIndex]
                                                        .videoReview
                                                    }
                                                    onChange={(e) =>
                                                      setWeekDataValue(
                                                        yearIndex,
                                                        monthIndex,
                                                        weekIndex,
                                                        "videoReview",
                                                        e.target.value
                                                      )
                                                    }
                                                    style={{
                                                      width: "98%",
                                                      height: "100px",
                                                      padding: "10px 15px",
                                                    }}
                                                  ></textarea>
                                                ) : (
                                                  <p>{weekData.videoReview}</p>
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              <h5>
                                                Upload Video{" "}
                                                <span> *(On Day 3) </span>
                                              </h5>
                                              <input
                                                type="file"
                                                onChange={(e) =>
                                                  handlePatientVideoUpload(
                                                    yearIndex,
                                                    monthIndex,
                                                    weekIndex,
                                                    e.target.files[0]
                                                  )
                                                }
                                              />
                                              <button
                                                type="submit"
                                                className="btn btn-primary"
                                              >
                                                Submit Video
                                              </button>
                                              <div
                                                style={{ marginTop: "15px" }}
                                              >
                                                <h6
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Therapist Review
                                                </h6>
                                                <p>{weekData.videoReview}</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {props.edit && props.therapistView && (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "flex-end",
                                            marginTop: "15px",
                                            marginBottom: "15px",
                                          }}
                                        >
                                          <button
                                            type="submit"
                                            className="btn btn-primary"
                                          >
                                            Update Table Data
                                          </button>
                                        </div>
                                      )}
                                    </form>
                                  </AccordionBody>
                                </>
                              )}
                            </AccordionItem>
                          ))}
                        </AccordionBody>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </AccordionBody>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ActivityPage;
