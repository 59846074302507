import React from "react";
import "./Therapist.css";

function Therapist() {
  return (
    <div className="therapist-section">
      {/* <div className='mission-img'>
    <img src={require("../../assets/we.webp")} alt='mission'/>
</div> */}
      <div
        className="container therapist-div"
        style={{ padding: "0", paddingTop: "25px" }}
      >
        <h3>Our Therapists</h3>
        <div className="therapistContentDiv">
          {/* <div className="col-lg-5 therapist-left"> */}
          <img src={require("../../assets/who.webp")} alt="therapist" />
          {/* </div> */}

          {/* <div className="col-lg-5 therapist-right"> */}
          <p>
            All our therapists come with more than 5 years of experience in
            traditional speech therapy (experienced in both offline and online
            therapy) along with mastery in alternative therapies and
            methodologies such as prompt therapy, articulation therapy, oromotor
            stimulation, use of augmented communication devices etc. We maintain
            absolute transparency about the credentials and competency of our
            therapists, and you can review the same before booking a session
            with them. If you don’t like the therapist for any reason, you can
            choose a different therapist for the subsequent session!
          </p>
          {/* </div> */}
        </div>
      </div>
      <div className="child-img-container">
        <img
          className="children-img"
          src={require("../../assets/children.webp")}
          alt="child"
        />
      </div>
    </div>
  );
}

export default Therapist;
