import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import BasicNavbar from "../components/BasicNavbar";

function PrivacyPolicy() {
  return (
    <div className="App">
      <GoToTop />
      <div>
        <BasicNavbar />
      </div>
      <div className="content-div">
        <h1>Privacy Policy</h1>
        <p>
          Excelspeech4all ("we," "us," "our") values your privacy and is
          committed to protecting your personal information. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your data when
          you use our online therapy platform. By using Excelspeech4all, you
          consent to the practices described in this policy.
        </p>
        <ol>
          <li>
            <p className="list-header">Information We Collect</p>
            {/* <br /> */}
            <ol style={{ listStyle: "a" }}>
              <li>
                Personal Information: We may collect personal information from
                you when you register an account or use our services. This may
                include your name, email address, contact information, and
                billing details.
              </li>
              <li>
                Child's Information: To provide therapy services, we may collect
                information about your child needs, including their name, age,
                medical history, and therapy progress.
              </li>
              <li>
                Usage Information: We gather information about your interactions
                with our platform, such as session logs, IP addresses, browser
                type, and device information.
              </li>
            </ol>
          </li>
          <li>
            <p className="list-header">How We Use Your Information</p>
            {/* <br />  */}
            We use the information we collect for the following purposes:
            <ol>
              <li>
                Providing Services: To deliver therapy services to your child,
                including session scheduling and monitoring progress.
              </li>
              <li>
                Communication: To communicate with you regarding therapy
                sessions, updates, and administrative matters.
              </li>
              <li>
                Improvement: To enhance our platform's performance, features,
                and user experience.
              </li>
              <li>
                Billing: To process payments and manage billing-related
                activities.
              </li>
              <li>
                Legal Requirements: To comply with legal obligations and respond
                to legal requests.
              </li>
            </ol>
          </li>
          <li>
            <p className="list-header">Information Sharing</p>
            {/* <br /> */}
            We may share your information under the following circumstances:
            <ol>
              <li>
                Service Providers: We may share data with trusted service
                providers who assist us in delivering our services.
              </li>
              <li>
                Legal Compliance: We may disclose information to comply with
                legal requirements, enforce our policies, or protect our rights
                and safety.
              </li>
            </ol>
          </li>
          <li>
            <p className="list-header">Your Choices</p>
            {/* <br />  */}
            You have the following rights regarding your data:
            <ol>
              <li>
                Access and Update: You can access and update your account
                information at any time by logging into your account.
              </li>
              <li>
                Withdraw Consent: You can withdraw your consent for processing
                your data, but this may affect your ability to use our services.
              </li>
            </ol>
          </li>
          <li>
            <p className="list-header">Security</p>
            {/* <br />  */}
            We employ industry-standard security measures to protect your data
            from unauthorized access, disclosure, alteration, or destruction.
          </li>
          <li>
            <p className="list-header">Children's Privacy</p>
            {/* <br />  */}
            We are committed to protecting the privacy of children. We do not
            knowingly collect personal information from children under 13 years
            of age without parental consent.
          </li>

          <li>
            <p className="list-header">
              Excelspeech4all - Google Authentication and Calendar API Services
              User Data Policies & Terms and Conditions
            </p>
            {/* <br />  */}
            Why
            <br />
            Introduction <br />
            Excelspeech4all is an online therapy platform designed to provide
            therapy services for special children, offering parents the
            opportunity to access online video therapy sessions from listed
            therapists. To ensure a secure and efficient user experience, we
            have integrated Google Authentication and Calendar API Services into
            our platform. This document outlines the terms and conditions for
            the use of these services, explaining why we use them, what they
            entail, and how they are implemented.
            <br />
            What
            <br />
            Acceptance of Terms
            <br />
            By using the Google Authentication and Calendar API Services on
            Excelspeech4all, you agree to abide by these terms and conditions.
            We emphasize the importance of your understanding and acceptance of
            these terms for a safe and efficient experience on our platform.
            <br />
            Use of Google Authentication and Calendar API Services
            <br />
            Purpose
            <br />
            Excelspeech4all integrates Google Authentication and Calendar API
            Services to enhance the platform's capabilities. These services are
            employed for scheduling therapy sessions, ensuring user
            authentication, and facilitating secure communication.
            <br />
            Use of Google Calendar API in "Excelspeech4all"
            <br />
            We have a feature where, when a therapist clicks on a button to
            "create event and generate google meet link" for a particular
            appointment booked by a patient, it automatically creates a google
            calendar event with a unique google meet link with the patient as
            the attendee.
            <br />
            This event gets added to google calendars of both- the therapist
            that created the event, and the patient who was an attendee for that
            event. The patient and therapist can view the events in their google
            calendar through the embedded google calendar we have added to the
            website.
            <br />
            All of the above is achieved using google calendar api.
            <br />
            We request access to different information from the therapist and
            the patient though, as the patient will only view the events whereas
            the therapist can create and view them.
            <br />
            Use of Google Authentication in "Excelspeech4all"
            <br />
            We have used google sign in for both patients and therapists. This
            gives us access to info like their name, email address, profile
            picture associated with that google account.
            <br />
            Compliance
            <br />
            Your use of Google Authentication and Calendar API Services on
            Excelspeech4all must adhere to the intended purposes and Google's
            API Terms of Service
            (https://developers.google.com/terms/api-services-user-data-policy).
            This section clarifies what you can expect when utilizing these
            services and what is expected of you in return.
            <br />
            How
            <br />
            Data Handling and Privacy
            <br />
            Google's Data Handling
            <br />
            Google may collect and process user data during the use of Google
            Authentication and Calendar API Services on Excelspeech4all. This
            data handling is governed by Google's API Services User Data Policy
            (https://developers.google.com/terms/api-services-user-data-policy).
            <br />
            Excelspeech4all's Data Handling
            <br />
            Excelspeech4all is dedicated to safeguarding your privacy. To
            understand how we collect, utilize, and protect your personal
            information, please refer to our Privacy Policy, which elaborates on
            the specifics of data handling on our platform.
            <br />
            Security and Access Controls
            <br />
            Account Security
            <br />
            You are solely responsible for maintaining the security and
            confidentiality of your Excelspeech4all account and any API
            credentials provided to you. Here, we clarify the role you play in
            keeping your account secure.
            <br />
            Access Controls
            <br />
            You agree to implement necessary access controls and security
            measures to protect the data obtained through Google Authentication
            and Calendar API Services on Excelspeech4all. This section details
            how you can maintain control over data access.
            <br />
            Compliance
            <br />
            We reiterate the importance of compliance with laws, regulations,
            and Google's API Terms of Service when using these services. This
            section provides a clear understanding of your responsibilities to
            ensure compliance.
            <br />
            Liability
            <br />
            Both Excelspeech4all and Google bear no liability for any damages
            resulting from the use of Google Authentication and Calendar API
            Services on our platform. We explain the limits of liability for all
            parties involved.
            <br />
            Termination
            <br />
            Excelspeech4all reserves the right to terminate or suspend your
            access to Google Authentication and Calendar API Services in cases
            of violations or misconduct. We outline the procedures involved in
            the event of termination.
          </li>
          <li>
            <p className="list-header">Changes to Terms and Conditions</p>
            Excelspeech4all may update these terms and conditions as needed.
            This section elucidates how updates will be communicated and how
            your continued use of the services signifies acceptance of these
            revisions.
          </li>
          <li>
            <p className="list-header">Contact Information</p>
            We provide our contact details for any questions, concerns, or
            further information regarding these terms and conditions or our
            services.
          </li>
          <li>
            <p className="list-header">Conclusion</p>
            Thank you for choosing Excelspeech4all for your child's therapy
            needs. We are committed to transparency and providing a secure,
            efficient, and compliant platform for your therapy sessions.
          </li>
        </ol>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
