import React, { useState } from "react";
import "./AppointmentCard.css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { toast } from "react-toastify";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import { Navigate, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";

function EnquiryCard(props) {
  const navigate = useNavigate();

  function appointmentCardClicked() {
    navigate("/therapist/patient/patient-detail");

    // window.location.href="patient/patient-detail";
  }
  function gototherapistdetail() {
    navigate("/admin/therapist/therapist-detail");

    // window.location.href="therapist/therapist-detail";
  }
  return (
    <>
      <div className="row appointmentCard" style={{ cursor: "pointer" }}>
        <div
          className="col col-xs-12 col-md-5  imgContent"
          onClick={props.admin ? appointmentCardClicked : gototherapistdetail}
        >
          <div className="therapistImgDiv">
            {props.data.patient.profilePic === null ? (
              <img
                className="therapistImg"
                src={require("../../images/person.jpg")}
                alt="appimg"
              />
            ) : (
              <img
                className="therapistImg"
                src={`${BASE_URL}/upload/${props.data.patient.profilePic}`}
                alt="appimg"
              />
            )}
            {/* <img
              className="therapistImg"
              src={require("../../images/person.jpg")}
              alt="appimg"
            /> */}
          </div>
          <div className="therapistContent">
            <h5>{props.data.patient.studentName}</h5>
            <p>{props.data.patient.country}</p>
            {/* {props.admin&&<p><BusinessCenterOutlinedIcon /> 10+ years</p>} */}
          </div>
        </div>
        <div className="col col-xs-12 col-md-6 row btnGroup ">
          <div className="dateTime-btn col-11 col-sm-12">
            {props.admin ? (
              <p onClick={appointmentCardClicked}>View Profile</p>
            ) : (
              <>
                <p>
                  <DateRangeIcon style={{ fill: "#7457fb" }} />{" "}
                  {`${props.data.date} ${props.data.currentYear}`}
                </p>
                <p>
                  <AccessTimeOutlinedIcon style={{ fill: "#f27d58" }} />{" "}
                  {`${props.data.startTime}-${props.data.endTime}`}
                  PM
                </p>
              </>
            )}
          </div>

          <div className="row col-12 bottomBtnDiv justify-content-between">
            <div
              style={{ color: "#fff" }}
              className="action-btn approve-btn col-5"
            >
              Confirm
            </div>

            {/* <div className=" join-btn disapprove-btn col-6">Disapprove</div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default EnquiryCard;

// import React, { useState } from "react";
// import "./AppointmentCard.css";
// import DateRangeIcon from "@mui/icons-material/DateRange";
// import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
// import { toast } from "react-toastify";
// import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
// import { Navigate, useNavigate } from "react-router-dom";

// function EnquiryCard(props) {
//   const navigate = useNavigate();

//   function appointmentCardClicked() {
//     navigate("/therapist/patient/patient-detail");

//     // window.location.href="patient/patient-detail";
//   }
//   function gototherapistdetail() {
//     navigate("/admin/therapist/therapist-detail");

//     // window.location.href="therapist/therapist-detail";
//   }
//   return (
//     <>
//       <div className="row appointmentCard" style={{ cursor: "pointer" }}>
//         <div
//           className="col col-xs-12 col-md-5  imgContent"
//           onClick={props.admin ? gototherapistdetail : appointmentCardClicked}
//         >
//           <div className="therapistImgDiv">
//             <img
//               className="therapistImg"
//               src={require("../../images/person.jpg")}
//               alt="appimg"
//             />
//           </div>
//           <div className="therapistContent">
//             <h5>Emilia Winson</h5>
//             <p>London, UK</p>
//             {/* {props.admin&&<p><BusinessCenterOutlinedIcon /> 10+ years</p>} */}
//           </div>
//         </div>
//         <div className="col col-xs-12 col-md-6 row btnGroup ">
//           <div className="dateTime-btn col-11 col-sm-12">
//             {props.admin ? (
//               <p onClick={gototherapistdetail}>View Profile</p>
//             ) : (
//               <>
//                 <p>
//                   <DateRangeIcon style={{ fill: "#7457fb" }} /> 19 July 2023
//                 </p>
//                 <p>
//                   <AccessTimeOutlinedIcon style={{ fill: "#f27d58" }} /> 12:00
//                   PM
//                 </p>
//               </>
//             )}
//           </div>

//           <div className="row col-12 bottomBtnDiv justify-content-between">
//             <div
//               style={{ color: "#fff" }}
//               className="action-btn approve-btn col-5"
//             >
//               Approve
//             </div>

//             <div className=" join-btn disapprove-btn col-6">Disapprove</div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default EnquiryCard;
