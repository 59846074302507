import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../apiConfig";
import { useNavigate } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";
import GroupsIcon from "@mui/icons-material/Groups";

const Sideitems = (props) => {
  const navigate = useNavigate();
  // const [codeClient, setCodeClient] = useState({}); // auth code flow

  // const google = window.google;
  // const CLIENT_ID =
  //   "1045090402386-nmcvrqidaj4qcnphh8t5bvq1hn469kh2.apps.googleusercontent.com";

  // useEffect(() => {
  //   // AUTH CODE FLOW
  //   setCodeClient(
  //     google.accounts.oauth2.initCodeClient({
  //       client_id: CLIENT_ID,
  //       // scope: "https://www.googleapis.com/auth/calendar.readonly",
  //       scope: "https://www.googleapis.com/auth/calendar",
  //       ux_mode: "popup",
  //       callback: (response) => {
  //         const code = response.code;
  //         const code_receiver_uri = `${BASE_URL}/patient/googleAuthFlow`; // Replace with your code receiver URI

  //         fetch(code_receiver_uri, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/x-www-form-urlencoded",
  //             // Set custom header for CSRF
  //             "X-Requested-With": "XmlHttpRequest",
  //           },
  //           body: `code=${code}`,
  //           credentials: "include",
  //         })
  //           .then((response) => response.text())
  //           .then((data) => {
  //             console.log("Auth code response: " + data);
  //           })
  //           .catch((error) => {
  //             console.error("Error in fetch:", error);
  //           });
  //       },
  //     })
  //   );

  //   // codeClient.requestCode(); //requestCode method is avialable on the codeClient object we got when we initialised codeClient
  // }, []);

  function gototherapist() {
    navigate("/therapist");
  }
  function gotoappointment() {
    navigate("/therapist/appointment");
  }
  function gotopatient() {
    navigate("/therapist/patients");
  }
  function gotocalendar() {
    navigate("/therapist/calendar");
  }
  function gotoenquiry() {
    navigate("/therapist/enquiry");
  }

  function patientsdashboard() {
    navigate("/patient");
  }
  function patientsappointment() {
    navigate("/patient/therapists");
  }
  function patientscalendar() {
    // codeClient.requestCode(); //requestCode method is avialable on the codeClient object we got when we initialised codeClient

    navigate("/patient/calendar");
  }
  function patientsprogress() {
    navigate("/patient/progress");
  }
  function adminDashboard() {
    navigate("/admin");
  }
  function adminTherapists() {
    navigate("/admin/therapists");
  }
  function adminPatients() {
    navigate("/admin/patients");
  }
  function adminEnquiry() {
    navigate("/admin/enquiry");
  }

  return (
    <React.Fragment>
      {props.pov === "therapist" && (
        <>
          <ListItemButton onClick={gototherapist}>
            <ListItemIcon>
              <HomeOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>

          <ListItemButton onClick={gotoappointment}>
            <ListItemIcon>
              <DateRangeOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="All Appointments" />
          </ListItemButton>
          <ListItemButton onClick={gotopatient}>
            <ListItemIcon>
              <PersonOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Patients" />
          </ListItemButton>
          <ListItemButton onClick={gotocalendar}>
            <ListItemIcon>
              <CalendarTodayIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Calendar" />
          </ListItemButton>
          {/* <ListItemButton onClick={gotoenquiry}>
            <ListItemIcon>
              <CalendarTodayIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Enquiry" />
          </ListItemButton> */}
        </>
      )}
      {props.pov === "patient" && (
        <>
          <ListItemButton onClick={patientsdashboard}>
            <ListItemIcon>
              <HomeOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>

          <ListItemButton onClick={patientsappointment}>
            <ListItemIcon>
              <GroupsIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Therapists" />
          </ListItemButton>
          <ListItemButton onClick={patientsprogress}>
            <ListItemIcon>
              <TimelineIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="My Progress" />
          </ListItemButton>
          <ListItemButton onClick={patientscalendar}>
            <ListItemIcon>
              <CalendarTodayIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Calendar" />
          </ListItemButton>
        </>
      )}
      {props.pov === "admin" && (
        <>
          <ListItemButton onClick={adminDashboard}>
            <ListItemIcon>
              <HomeOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          <ListItemButton onClick={adminTherapists}>
            <ListItemIcon>
              <PersonOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Therapists" />
          </ListItemButton>
          <ListItemButton onClick={adminPatients}>
            <ListItemIcon>
              <PersonOutlinedIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Patients" />
          </ListItemButton>
          <ListItemButton onClick={adminEnquiry}>
            <ListItemIcon>
              <CalendarTodayIcon sx={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Enquiries" />
          </ListItemButton>
        </>
      )}
    </React.Fragment>
  );
};

export default Sideitems;
