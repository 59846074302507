import React, { useState } from "react";
import { BASE_URL } from "../../apiConfig";
import classes from "./AdminLogin.module.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // FOR ADMIN LOGIN
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/admin/login`,
        { username, password },
        { withCredentials: true }
      );
      console.log(data);
      const { success, message } = data;
      if (success) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/admin");
        }, 1200);
      } else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setUsername("");
    setPassword("");
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.formContainer}>
          <form onSubmit={handleSubmit}>
            <h3>Admin Log In</h3>
            <div className="form-group">
              <input
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                type="text"
                className="form-control"
                name="username"
                placeholder="Enter username"
              />
            </div>
            <div className="form-group">
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter password"
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Log In
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AdminLogin;
