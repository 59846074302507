import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import BasicNavbar from "../components/BasicNavbar";

function TermsConditions() {
  return (
    <div className="App">
      <GoToTop />
      <div>
        <BasicNavbar />
      </div>
      <div className="content-div">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to Excelspeech4all, an online therapy platform dedicated to
          providing remote speech therapy services for children in need along
          with parental training. Before you use our services, please carefully
          read and understand the following Terms and Conditions (the
          "Agreement"). By accessing or using our platform, you agree to comply
          with and be bound by these terms. If you do not agree with any part of
          this Agreement, please do not use our services.
        </p>
        <ol>
          <li>
            <p className="list-header">Definitions</p>
            {/* <br /> */}
            "Excelspeech4all," "we," "us," or "our" refers to the online speech
            therapy platform Excelspeech4all. "User," "you," or "your" refers to
            parents or legal guardians who access and use the Excelspeech4all
            platform.
          </li>
          <li>
            <p className="list-header">Eligibility</p>
            {/* <br />  */}
            Users must be at least 18 years old and legally capable of entering
            into a contract. Users must be parents or legal guardians of a child
            for whom the speech therapy services are sought.
          </li>
          <li>
            <p className="list-header">Services</p>
            {/* <br /> */}
            Excelspeech4all offers online video therapy sessions for the
            children in need along with the parental training, delivered by
            qualified therapists. Therapy services include one on one speech
            therapy with the child in need,regular parental training,videos and
            documents to aid with parent-led therapy at home,activity tracker
            etc.
          </li>
          <li>
            <p className="list-header">Registration</p>
            {/* <br />  */}
            Users must create an account to access Excelspeech4all's services.
            You agree to provide accurate and complete information during the
            registration process. Users are responsible for maintaining the
            confidentiality of their account credentials.
          </li>
          <li>
            <p className="list-header">Payment and Billing</p>
            {/* <br />  */}
            Users will be charged for therapy sessions as per Excelspeech4all's
            pricing and billing policies. Payments are processed securely
            through our payment gateway.
          </li>
          <li>
            <p className="list-header">Privacy</p>
            {/* <br />  */}
            Excelspeech4all takes your privacy seriously. Please refer to our
            Privacy Policy for details on how we collect, use, and protect your
            personal information.
          </li>
          <li>
            <p className="list-header">User Responsibilities</p>
            {/* <br />  */}
            You agree to use Excelspeech4all for lawful and ethical purposes
            only. <br />
            You are responsible for ensuring a suitable and safe environment for
            therapy sessions, including your child's physical surroundings and
            internet connection. <br />
            Users are expected to treat therapists and other users with respect
            and professionalism.
          </li>
          <li>
            <p className="list-header">Cancellation and Refunds</p>
            {/* <br />  */}
            Cancellation policies and refund procedures are outlined in our
            Refund and Cancellation Policy.
          </li>
          <li>
            <p className="list-header">Intellectual Property</p>
            {/* <br />  */}
            All content and materials on Excelspeech4all are protected by
            intellectual property laws and may not be used without our explicit
            consent.
          </li>
          <li>
            <p className="list-header">Termination</p>
            {/* <br />  */}
            Excelspeech4all reserves the right to terminate or suspend user
            accounts for violations of these terms or any other misconduct.
          </li>
          <li>
            <p className="list-header">Limitation of Liability</p>
            {/* <br />  */}
            Excelspeech4all is not liable for any direct, indirect, incidental,
            or consequential damages resulting from your use of our platform.
          </li>
          <li>
            <p className="list-header">Changes to Terms and Conditions</p>
            {/* <br />  */}
            Excelspeech4all may update these terms from time to time. You will
            be notified of significant changes, and your continued use of our
            platform constitutes acceptance of the revised terms.
          </li>
          <li>
            <p className="list-header">Governing Law</p>
            {/* <br />  */}
            This Agreement is governed by and construed in accordance with the
            local laws.
          </li>
          <li>
            <p className="list-header">Contact Information</p>
            {/* <br />  */}
            If you have any questions or concerns regarding these terms and
            conditions or our services, please contact us at
            admin@xlspeech4all.com.
          </li>
        </ol>
        <p>
          By using Excelspeech4all, you acknowledge that you have read,
          understood, and agree to these Terms and Conditions. Thank you for
          choosing Excelspeech4all to support your child's therapy needs.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsConditions;
