import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import TextField from "@mui/material/TextField";
// import './Patient.css'
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import "./PatientProfile.css";
import { ToastContainer, toast } from "react-toastify";

export default function PatientProfile() {
  const [patient, setPatient] = useState({
    studentName: "",
    parentName: "",
    age: "",
    primaryLanguage: "",
    country: "",
    timeZone: "",
    email: "",
    expectations: "",
    profilePic: null,
  });
  const [displayPic, setDisplayPic] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const {
    studentName,
    parentName,
    age,
    primaryLanguage,
    country,
    timeZone,
    email,
    expectations,
    profilePic,
  } = patient;

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        // const patientId = "64c108530d99356de2475a8d";
        const response = await axios.get(`${BASE_URL}/patient/details`, {
          withCredentials: true,
        });
        const patientObj = response.data;
        setPatient({
          studentName: patientObj.studentName,
          parentName: patientObj.parentName,
          age: patientObj.age,
          primaryLanguage: patientObj.primaryLanguage,
          country: patientObj.country,
          timeZone: patientObj.timeZone,
          email: patientObj.email,
          expectations: patientObj.expectations,
          profilePic: patientObj.profilePic,
        });
        setDisplayPic(patientObj.profilePic);
        setIsLoading(false);
        console.log(patientObj);
      } catch (error) {
        console.error("Error fetching patient:", error);
        setIsLoading(false);
      }
    };

    fetchPatient();
  }, []);

  function changeHandler(e) {
    const { name, value } = e.target;
    setPatient((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  }

  function handleProfilePicChange(e) {
    const newPic = e.target.files[0];
    setPatient((prevDetails) => ({ ...prevDetails, profilePic: newPic }));
  }

  async function submitProfile(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("studentName", studentName);
    formData.append("parentName", parentName);
    formData.append("age", age);
    formData.append("primaryLanguage", primaryLanguage);
    formData.append("country", country);
    formData.append("timeZone", timeZone);
    formData.append("email", email);
    formData.append("expectations", expectations);
    formData.append("profilePic", profilePic);

    try {
      await axios.post(`${BASE_URL}/patient/editProfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      console.log("Successfully updated!");
      toast.success("Details updated!");
    } catch (error) {
      toast.error("An error occurred!");
      console.error("Error during editing details");
    }
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "0px 0 10px 0",
        }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div className="container rounded bg-white  mb-2">
            <div className="row">
              <div className="col-md-4 border-right">
                <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                  <img
                    alt="profile"
                    className="rounded-circle mt-5"
                    width="150px"
                    src={`${BASE_URL}/upload/${displayPic}`}
                  />
                  <span className="font-weight-bold">{studentName}</span>
                  <span className="text-black-50">{email}</span>
                  <span> </span>
                </div>
              </div>
              <div className="col-md-7 border-right">
                <div className="p-3 py-5">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right">Profile Settings</h4>
                  </div>
                  <form onSubmit={submitProfile}>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label className="labels">Student Name*</label>
                        <input
                          name="studentName"
                          required
                          type="text"
                          className="form-control"
                          placeholder="e.g. John"
                          value={studentName}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="labels">Parent Name*</label>
                        <input
                          name="parentName"
                          required
                          type="text"
                          className="form-control"
                          value={parentName}
                          placeholder="e.g. Mitchell"
                          onChange={changeHandler}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="labels">Age*</label>
                        <input
                          name="age"
                          required
                          type="text"
                          className="form-control"
                          placeholder="6"
                          value={age}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Language Spoken*</label>
                        <input
                          type="text"
                          name="primaryLanguage"
                          required
                          className="form-control"
                          placeholder="English"
                          value={primaryLanguage}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Time zone*</label>
                        <input
                          required
                          name="timeZone"
                          type="text"
                          className="form-control"
                          placeholder="GMT+05:30"
                          value={timeZone}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Country*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="India"
                          value={country}
                          onChange={changeHandler}
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Email ID</label>
                        <input
                          name="email"
                          required
                          type="text"
                          className="form-control"
                          placeholder="john@gmail.com"
                          value={email}
                          disabled
                        />
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Profile Picture</label>
                        <input
                          type="file"
                          name="profilePic"
                          className="form-control"
                          onChange={handleProfilePicChange}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <label className="labels">What you expect?</label>
                      <TextField
                        value={expectations}
                        fullWidth
                        id="outlined-multiline-flexible"
                        name="expectations"
                        multiline
                        maxRows={3}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="mt-5 text-center">
                      <button
                        className="btn btn-primary profile-button"
                        // onClick={submitProfile}
                        type="submit"
                      >
                        Save Profile
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="col-md-4">
            <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center experience"><span>Edit Experience</span><span className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Experience</span></div><br/>
                <div className="col-md-12"><label className="labels">Experience in Designing</label><input type="text" className="form-control" placeholder="experience" value=""/></div> <br/>
                <div className="col-md-12"><label className="labels">Additional Details</label><input type="text" className="form-control" placeholder="additional details" value=""/></div>
            </div>
        </div> */}
            </div>
          </div>
        )}
        <ToastContainer />
      </Container>
    </Box>
  );
}
