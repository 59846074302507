import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const sendToHome = () => {
    if (
      location.pathname === "/patient/auth" ||
      "/therapist/auth" ||
      "/terms-conditions" ||
      "/privacy-policy"
    ) {
      sendToHomePageSection("home");
      console.log("home");
    }
    const element = document.getElementById("home");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const sendToAbout = () => {
    if (
      location.pathname === "/patient/auth" ||
      "/therapist/auth" ||
      "/terms-conditions" ||
      "/privacy-policy"
    ) {
      sendToHomePageSection("about");
    }
    const element = document.getElementById("about");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendToProgram = () => {
    if (
      location.pathname === "/patient/auth" ||
      "/therapist/auth" ||
      "/terms-conditions" ||
      "/privacy-policy"
    ) {
      sendToHomePageSection("program");
    }
    const element = document.getElementById("program");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  async function sendToHomePage() {
    await navigate("/");
    const element = document.getElementById("home");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  async function sendToHomePageSection(section) {
    await navigate("/"); // Wait for the navigation to complete

    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      console.log("scrolling");
    }
  }

  return (
    <>
      <footer
        className="text-center text-lg-start text-white"
        style={{ backgroundColor: "#11112b" }}
      >
        <section className="">
          <div className="container py-4 text-center text-md-start ">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h3
                  className="text-uppercase fw-bold"
                  onClick={sendToHomePage}
                  style={{ cursor: "pointer" }}
                >
                  XlSpeech4All
                </h3>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#00bf63",
                    height: "2px",
                  }}
                />
                <p>
                  Ensure high quality services to empower each and every
                  individual by bringing out their innate ability to speak and
                  communicate by providing the right stimulus at the right time
                  without any geographical constraints.
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h4 className="text-uppercase">Information</h4>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#00bf63",
                    height: "2px",
                  }}
                />
                <p className="links" onClick={sendToHome}>
                  {" "}
                  Home
                </p>
                <p className="links" onClick={sendToAbout}>
                  About
                </p>
                <p className="links" onClick={sendToProgram}>
                  Program
                </p>
                {/* <p className='links'>Let's Talk</p> */}
              </div>

              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <h4 className="text-uppercase">Legal</h4>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#00bf63",
                    height: "2px",
                  }}
                />
                <p className="links">
                  <Link className="links" to="/terms-conditions">
                    Terms & Conditions
                  </Link>
                </p>

                <p className="links">
                  <Link className="links" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </p>

                <p className="links">
                  <Link className="links" to="/refund-cancellation-policy">
                    Refund & Cancellation Policy
                  </Link>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h4 className="text-uppercase">Support</h4>

                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#00bf63",
                    height: "2px",
                  }}
                />
                {/* <p> <LocationOnIcon fontSize='large'/> &Work Coworking Plot No. 5B, Sector 15A Neelam Chowk Ajronda Metro Near Crown Plaza, Faridabad, Haryana 121007</p> */}
                <p className="links">
                  <Link className="links" to="mailto:xlspeech@gmail.com">
                    <EmailIcon fontSize="medium" /> admin@xlspeech4all.com
                  </Link>
                </p>
                <p>
                  <Link className="links" to="tel:+918310967700">
                    <LocalPhoneIcon fontSize="medium" /> +91 831 096 7700
                  </Link>
                </p>
                <p>
                  <Link className="links" to="tel:+917795238578">
                    <LocalPhoneIcon fontSize="medium" /> +91 779 523 8578
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          <p> © 2023 Copyright : XLSPEECH4ALL</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
