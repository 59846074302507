import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import timezones from "../../named-timezones-list.json";
import countries from "../../countries.json";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { BASE_URL } from "../../apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../_mainWebsite/components/Footer";
import BasicNavbar from "../../_mainWebsite/components/BasicNavbar";

function TherapistSignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const [certificate, setCertificate] = useState(1);
  const [language, setLanguage] = useState(1);

  const [titles, setTitles] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const [formData, setFormData] = useState({
    fullName: "",
    yearsOfExp: "",
    country: "",
    // email: "",
    password: "",
    confirmPassword: "",
    overviewOfExp: "",
    phoneNumber: "",
    langSpoken: [],
    therapySpecialisation: "",
    timezone: "",
  });

  const { email } = location.state || {}; //email passed from therapist/auth to this (therapist/signup)

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // setFormData({ ...formData, [name]: value });
  };

  const [selectedFiles, setSelectedFiles] = useState([]); //Array of selected certificate files

  const handleTitleChange = (index, value) => {
    const updatedTitles = [...titles];
    updatedTitles[index] = value;
    setTitles(updatedTitles);
  };

  const handleFileChange = (index, e) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles[index] = e.target.files[0];
    setSelectedFiles(updatedSelectedFiles);
  };

  const addCertificate = () => {
    setCertificate(certificate + 1);
    setTitles([...titles, ""]);
    setCertificates([...certificates, null]);
  };

  const addLanguage = () => {
    setLanguage(language + 1);
    setFormData((prevData) => ({
      ...prevData,
      langSpoken: [...prevData.langSpoken, ""], // Add an empty language
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.langSpoken.length <= 0) {
      toast.error("Please add languages spoken!");
      return;
    }

    if (titles.length <= 0 || selectedFiles <= 0) {
      toast.error("Please add certificates!");
      return;
    }

    const formDataToSubmit = new FormData();

    formDataToSubmit.append("email", email);

    const nonEmptyLanguages = formData.langSpoken.filter(
      (language) => language !== ""
    );

    // Append the non-empty languages to the formData as a single dimension array
    nonEmptyLanguages.forEach((language) => {
      formDataToSubmit.append("langSpoken[]", language);
    });

    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "langSpoken") {
        formDataToSubmit.append(key, value);
      }
    });

    // Append certificate data to the formData
    titles.forEach((title, index) => {
      formDataToSubmit.append("titles[]", title);
      formDataToSubmit.append("certifications", selectedFiles[index]);
    });

    const response = await fetch(`${BASE_URL}/therapistSignup`, {
      method: "POST",
      body: formDataToSubmit,
      credentials: "include",
    });

    if (response.ok) {
      toast.success("Successfully signed up!");
      console.log("Therapist signup successfully!");
      navigate("/therapist");
    } else {
      toast.error("Error during sign up!");

      console.error("Failed to sign up");
    }
  };

  return (
    <>
      <BasicNavbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 5,
            // "@media (max-width: 768px)": {
            //   marginTop: 10, // Adjust the margin for screens 768px and smaller
            //   marginBottom: 3,
            // },
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Let's create your account
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  // autoComplete="given-name"
                  name="fullName"
                  required
                  fullWidth
                  id="fullName"
                  label="Name"
                  autoFocus
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="yearsOfExp"
                  label="Years of Experience (eg: 5)"
                  //type="text"
                  id="yearsOfExp"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  value={email}
                  required
                  fullWidth
                  name="email"
                  label="Email Address"
                  type="email"
                  id="email"
                  autoComplete="email"
                  // onChange={handleInputChange}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="phoneNumber"
                  required
                  type="tel"
                  fullWidth
                  id="phoneNumber"
                  label="Phone Number (10 digits)"
                  onChange={handleInputChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="country"
                  aria-label="Floating label select example"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                >
                  <option selected>Select Country*</option>
                  <option value="India">India</option>
                  <option value="Russia">Russia</option>
                  <option value="America">America</option>
                </select>
                <label for="country">Find Your Country</label>
              </div>
            </Grid> */}
              <Grid item xs={12} sm={6}>
                <div class="form-floating">
                  <select
                    class="form-select"
                    id="country"
                    aria-label="Floating label select example"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  >
                    <option selected>Select Country*</option>
                    {/* <option value="India">India</option>
                  <option value="Russia">Russia</option>
                  <option value="America">America</option> */}
                    {countries?.map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                  <label for="country">Find Your Country</label>
                </div>
              </Grid>

              {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="timezone"
                label="Timezone"
                name="timezone"
                autoComplete="family-name"
                onChange={handleInputChange}
              />
            </Grid> */}

              <Grid item xs={12} sm={6}>
                <div class="form-floating">
                  <select
                    class="form-select"
                    id="timezone"
                    aria-label="Floating label select example"
                    name="timezone"
                    value={formData.timezone}
                    onChange={handleInputChange}
                  >
                    <option selected>Select Timezone*</option>
                    {/* <option value="India">India</option>
                  <option value="Russia">Russia</option>
                  <option value="America">America</option> */}
                    {timezones?.map((zone) => (
                      <option value={zone}>{zone}</option>
                    ))}
                  </select>
                  <label for="timezone">Find your Timezone</label>
                </div>
              </Grid>

              {[...Array(formData.langSpoken.length)].map((_, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    required
                    fullWidth
                    id={`langSpoken${index}`}
                    label={`Language ${index + 1}`}
                    name={`langSpoken[${index}]`}
                    autoComplete="family-name"
                    onChange={handleInputChange}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button onClick={addLanguage}>Add Languages Spoken*</Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  name="overviewOfExp"
                  label="Overview of Experience (3-5 lines)"
                  type="text"
                  id="overviewOfExp"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="therapySpecialisation"
                  label="Specializations related to Speech Therapy"
                  name="therapySpecialisation"
                  onChange={handleInputChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                autoComplete="new-password"
                onChange={handleInputChange}
              />
            </Grid> */}
              {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                onChange={handleInputChange}
              />
            </Grid> */}
              {[...Array(certificate)].map((_, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      // id={`titles${index}`}
                      label="Certificate Title"
                      // name={`titles[${index}]`}
                      value={titles[index] || ""}
                      onChange={(e) => handleTitleChange(index, e.target.value)}
                      // onChange={(e) => handleCertificateChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label htmlFor={`certificateImage${index}`}>
                      Certificate Image
                    </label>
                    <input
                      // id={`certificateImage${index}`}
                      type="file"
                      // onChange={(e) => handleCertificateImageChange(index, e)}
                      onChange={(e) => handleFileChange(index, e)}
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Button onClick={addCertificate}>Add Certificate(s)</Button>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            {/* <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
          </Box>
        </Box>
        <ToastContainer />
      </Container>
      <Footer />
    </>
  );
}

export default TherapistSignUp;
