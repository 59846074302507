import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Calendar from "moedim";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TimePicker } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "./Calendar.css";
import GoogleCalendar from "./GoogleCalendar";
import { BASE_URL } from "../../apiConfig";
import { toast, ToastContainer } from "react-toastify";

export function BasicDateCalendar() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar />
    </LocalizationProvider>
  );
}

const myStyle = {
  closeIcon: {
    cursor: "pointer",
  },
  calendar: {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
  },
};
export default function TherapistCalendar() {
  const [date, setDate] = useState(new Date());
  const [slotCards, setSlotCards] = useState([]);
  const [slotDate, setSlotDate] = useState("");
  const [slotTime, setSlotTime] = useState("0");

  const [startingTime, setStartingTime] = useState("");
  const [endingTime, setEndingTime] = useState("");

  const calendarChange = (d) => {
    setSlotDate(d.toString().slice(3, 10));
    setDate(d);
  };

  const [time, setTime] = useState([]);

  const timeChange = (time, timeString) => {
    // console.log(time);
    // console.log(timeString);

    setStartingTime(timeString[0]);
    setEndingTime(timeString[1]);

    console.log(timeString[0]);
    console.log(timeString[1]);

    setTime(time);
    setSlotTime(timeString[0] + " - " + timeString[1]);
  };

  const AddSlot = (event) => {
    setSlotCards(slotCards.concat(AddSlotCard(slotDate, slotTime)));

    const postData = {
      date: slotDate,
      startingTime: startingTime,
      endingTime: endingTime,
    };

    fetch(`${BASE_URL}/slot/addslot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("hello");
        console.log(data);
        if (data.success) {
          toast.success(data.message);
          setSlotData((prevSlots) => [...prevSlots, data.newSlot]);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Error adding slot!");
      });
  };

  const removeCard = (slotId) => {
    console.log("clicked");
    // slotCards.slice(0, -1);
    // const newSlotCards = slotCards;
    // setSlotCard(newSlotCards);

    // setSlotCards((prevSlotCards) =>
    //   prevSlotCards.filter((card) => card.id !== slotId)
    // );

    fetch(`${BASE_URL}/slot/deleteslot/${slotId}`, {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("hello");
        console.log(data);
        if (data.success) {
          toast.success(data.message);
          setSlotData((prevSlots) =>
            prevSlots.filter((slot) => slot._id !== slotId)
          );
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        toast.error("Error deleting slot!");
        console.error("Error:", error);
      });
  };

  const [slotData, setSlotData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/therapist/details`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setSlotData(data.therapist.mainSlot);
        console.log(data.therapist.mainSlot);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, []);

  const AddSlotCard = (slotId, date, time) => {
    return (
      <Grid item>
        <Paper
          onClick={() => removeCard(slotId)}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 80,
            background: "#ececec",
            position: "relative",
            minWidth: "180px",
            margin: "10px",
          }}
        >
          <h6>{date}</h6>
          <h6>{time}</h6>
          <CloseOutlinedIcon
            className="closeIcon"
            style={myStyle.closeIcon}
            fontSize="small"
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
          />
        </Paper>
      </Grid>
    );
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px 0 10px 0",
        }}
      >
        <h5>Add your slot timing</h5>
        <Grid
          container
          className="calendarContainer"
          sm={12}
          spacing={2}
          sx={{ padding: 2 }}
        >
          {/* <Grid item sm={0} md={1}>
                                                                                            
                </Grid> */}
          <Grid item xs={12} sm={10} md={6}>
            <Calendar value={date} onChange={(d) => calendarChange(d)} />
            {/* <BasicDateCalendar value={date} onChange={(d) => calendarChange(d)} /> */}
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Grid item>
              {/* <TimeRange
                            onStartTimeChange={returnFunctionStart}
                            onEndTimeChange={returnFunctionEnd}
                            startMoment={startTime}
                            endMoment={endTime}
                            minuteIncrement={15}
                            sameIsValid={false}
                        /> */}
              <TimePicker.RangePicker
                sx={{
                  width: "100%",
                }}
                showSecond={false}
                minuteStep={10}
                // onSelect={updateSlot}
                onChange={timeChange}
              />
            </Grid>
            <Button
              style={{
                width: "260px",
                marginTop: "15px",
                backgroundColor: "#1d3e96",
              }}
              onClick={AddSlot}
              variant="contained"
            >
              Add
            </Button>

            <Grid container mt={2} xs={12} md={8} lg={12}>
              {/* {slotCards} */}
              {slotData?.map((slot) =>
                AddSlotCard(
                  slot._id,
                  slot.date,
                  `${slot.startingTime} - ${slot.endingTime}`
                )
              )}
            </Grid>
          </Grid>

          <Grid item sm={0} md={2}></Grid>
        </Grid>

        {/* <Copyright sx={{ pt: 4 }} /> */}
      </Container>
      <GoogleCalendar />
    </Box>
  );
}

// import * as React from "react";
// import { useState, useEffect} from "react";
// import {BASE_URL} from "../../apiConfig";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
// import Calendar from "moedim";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import { TimePicker } from "antd";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
// import "./Calendar.css";
// import GoogleCalendar from "./GoogleCalendar";

// export function BasicDateCalendar() {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DateCalendar />
//     </LocalizationProvider>
//   );
// }

// const myStyle = {
//   closeIcon: {
//     cursor: "pointer",
//   },
//   calendar: {
//     display: "flex",

//     justifyContent: "center",
//     alignItems: "center",
//   },
// };
// export default function TherapistCalendar() {
//   const [date, setDate] = useState(new Date());
//   const [slotCards, setSlotCard] = useState([]);
//   const [slotDate, setSlotDate] = useState("");
//   const [slotTime, setSlotTime] = useState("0");

//   const calendarChange = (d) => {
//     setSlotDate(d.toString().slice(3, 10));
//     setDate(d);
//   };

//   const [time, setTime] = useState([]);

//   const timeChange = (time, timeString) => {
//     // console.log(time)
//     // console.log(timeString);
//     setTime(time);
//     setSlotTime(timeString[0] + " - " + timeString[1]);
//   };

//   const AddSlot = (event) => {
//     setSlotCard(slotCards.concat(AddSlotCard(slotDate, slotTime)));
//   };
//   const removeCard = () => {
//     console.log("clicked");
//     slotCards.slice(0, -1);
//     const newSlotCards = slotCards;
//     setSlotCard(newSlotCards);
//   };

//   const AddSlotCard = (date, time) => {
//     return (
//       <Grid item>
//         <Paper
//           onClick={removeCard}
//           sx={{
//             p: 2,
//             display: "flex",
//             flexDirection: "column",
//             height: 80,
//             background: "#ececec",
//             position: "relative",
//             minWidth: "180px",
//             margin: "10px",
//           }}
//         >
//           <h6>{date}</h6>
//           <h6>{time}</h6>
//           <CloseOutlinedIcon
//             className="closeIcon"
//             style={myStyle.closeIcon}
//             fontSize="small"
//             sx={{
//               position: "absolute",
//               right: "10px",
//               top: "10px",
//             }}
//           />
//         </Paper>
//       </Grid>
//     );
//   };

//   return (
//     <Box
//       component="main"
//       sx={{
//         backgroundColor: (theme) =>
//           theme.palette.mode === "light"
//             ? theme.palette.grey[100]
//             : theme.palette.grey[900],
//         flexGrow: 1,
//         height: "100vh",
//         overflow: "auto",
//         padding: " 0 10px",
//       }}
//     >
//       <Toolbar />
//       <Container
//         maxWidth="lg"
//         sx={{
//           mt: 4,
//           mb: 4,
//           backgroundColor: "#fff",
//           borderRadius: "10px",
//           padding: "15px 0 10px 0",
//         }}
//       >
//         <h5>Add your slot timing</h5>
//         <Grid
//           container
//           className="calendarContainer"
//           sm={12}
//           spacing={2}
//           sx={{ padding: 2 }}
//         >
//           {/* <Grid item sm={0} md={1}>

//                 </Grid> */}
//           <Grid item xs={12} sm={10} md={6}>
//             <Calendar value={date} onChange={(d) => calendarChange(d)} />
//             {/* <BasicDateCalendar value={date} onChange={(d) => calendarChange(d)} /> */}
//           </Grid>
//           <Grid item xs={12} sm={10} md={6}>
//             <Grid item>
//               {/* <TimeRange
//                             onStartTimeChange={returnFunctionStart}
//                             onEndTimeChange={returnFunctionEnd}
//                             startMoment={startTime}
//                             endMoment={endTime}
//                             minuteIncrement={15}
//                             sameIsValid={false}
//                         /> */}
//               <TimePicker.RangePicker
//                 sx={{
//                   width: "100%",
//                 }}
//                 showSecond={false}
//                 minuteStep={10}
//                 // onSelect={updateSlot}
//                 onChange={timeChange}
//               />
//             </Grid>
//             <Button
//               style={{
//                 width: "260px",
//                 marginTop: "15px",
//                 backgroundColor: "#1d3e96",
//               }}
//               onClick={AddSlot}
//               variant="contained"
//             >
//               Add
//             </Button>

//             <Grid container mt={2} xs={12} md={8} lg={12}>
//               {slotCards}
//             </Grid>
//           </Grid>

//           <Grid item sm={0} md={2}></Grid>
//         </Grid>

//         {/* <Copyright sx={{ pt: 4 }} /> */}
//       </Container>
//       <GoogleCalendar />
//     </Box>
//   );
// }
